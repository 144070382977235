import React from 'react'
import { graphql } from 'gatsby'
import { Home } from '@templates'
import {
  prismicLogoBoardFields,
  prismicTextImageHeroFields,
  prismicGatsbyImageSharp,
  prismicBlogArticleFields,
  prismicCaseStudyCard,
  prismicSectorCard,
  prismicMailingListFields,
} from '@graphql/fragments'

const HomePage = ({ data }) => <Home data={data} />
HomePage.fragments = [
  prismicLogoBoardFields,
  prismicTextImageHeroFields,
  prismicBlogArticleFields,
  prismicCaseStudyCard,
  prismicSectorCard,
  prismicMailingListFields,
]
export default HomePage

export const query = graphql`
  query PrismicHomePage {
    prismic {
      allHome_pages {
        edges {
          node {
            _meta {
              uid
              id
            }
            hero {
              __typename
              ... on PRISMIC_Text_image {
                text
                image
                imageSharp {
                  childImageSharp {
                    ...prismicGatsbyImageSharp
                  }
                }
              }
            }
            body {
              ... on PRISMIC_Home_pageBodyCase_study {
                fields {
                  case_study {
                    ... on PRISMIC_Case_study {
                      ...prismicCaseStudyCard
                    }
                    __typename
                  }
                }
              }
            }
            seodescription
            seotitle
            page_title
            intro_heading
            intro_service {
              link_text
              service {
                ... on PRISMIC_Service {
                  _meta {
                    uid
                  }
                }
              }
            }
            text_column_one
            text_column_two
            slider_heading
            slider_articles {
              article {
                ...prismicBlogArticleFields
              }
            }
            sector_list_title
            sector_list {
                sector {
                    ...prismicSectorCard
                }
            }
            videoUrl: video_url {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            videoOverlayTextList: video_overlay_text_list {
              overlayText: overlay_text
            }
            content {
              content_item {
                ... on PRISMIC_Text_image {
                  ...prismicTextImageHeroFields
                }

                ... on PRISMIC_Logo_board {
                  ...prismicLogoBoardFields
                }
              }
            }
            mailinglist {
              ... on PRISMIC_Mailing_list {
                ...prismicMailingListFields
              }
            }
            slideshow_slides {
              image
              imageSharp {
                childImageSharp {
                  fixed(width: 1280, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

HomePage.query = query
